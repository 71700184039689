import { parseAnalytics } from '../../OC/utilities/analytics';

export default {
  Parse: subject => {
    // eslint-disable-next-line no-undef
    const site = /www\.(\w+)\.com/.exec(PB_SITE)[1];
    const isInternal = new RegExp(
      `^(https?://|)((w.)|(www.)|(w-devel.)|(w-stage.)|(w-prod.)|)(${site}).com`
    );
    const isSubdomain = new RegExp(
      `^https?:\\/\\/(\\w+\\.)+${site}\\.com`,
      'i'
    );
    const isOldCanadianSite = /^(https?:\/\/|\/\/|)(\w+\.)*owenscorning\.ca/i;

    let url = subject || '';

    let valid = true;
    let relative = true;
    let opensInBlank = false;

    // eslint-disable-next-line no-undef
    const analytics = parseAnalytics(PB_LINK_META[url]?.analytics || {});

    if (url.match(isSubdomain) && !url.match(isInternal)) {
      opensInBlank = true;
    }

    // Ensure any owenscorning.ca subdomain opens in a new tab
    if (url.match(isOldCanadianSite)) {
      opensInBlank = true;
    }

    // Check if it's an anchor link
    if (url.startsWith('#')) {
      return {
        subject,
        url,
        valid,
        relative,
        analytics,
        opensInBlank,
      }; // No transformation needed for anchor links
    }
    if (!url.startsWith('mailto:') && !url.startsWith('tel:')) {
      if (!url.match(/^[a-zA-Z]+:\/\//) && url.split('/')[0].includes('.')) url = `http://${ url }`;
      if (!url.match(/^[a-zA-Z]+:\/\//) && !url.startsWith('/')) url = `/${ url }`;
      relative =
        url.includes(window.location.hostname) ||
        url.match(isInternal) ||
        !url.match(/^[a-zA-Z]+:\/\//);
      if (url.includes('dms/')) relative = false;
    }
    // eslint-disable-next-line no-restricted-globals,no-undef
    if (location.host === PB_SITE) {
      // eslint-disable-next-line no-undef
      url = url.replace(isInternal, '') || `https://${PB_SITE}/`;
    }

    try {
      // eslint-disable-next-line no-new
      new URL(`${relative && 'https://www.owenscorning.com'}${url}`);
    } catch (_) {
      valid = false;
    }
    return { subject, url, valid, relative, analytics, opensInBlank };
  },
};
