import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Error = styled.span`
  color: #940420;
  display: block;
  font-size: 12px;
  margin-top: 8px;
`;

const StyledIcon = styled.i`
  color: #940420;
  margin-top: 8px;
`;

const Container = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;


const InputError = ({ message, icon = null }) => (
  <Container>
    {icon && <StyledIcon className="fa fa-times-circle fa-1x"></StyledIcon>}
    <Error>
      {message}
    </Error>
  </Container>
);

export default InputError;

InputError.propTypes = {
  /* HTML: The primary error text */
  message: PropTypes.node.isRequired,
  /* JSX: Controls rendering of an icon */
  icon: PropTypes.bool
}
