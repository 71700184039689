import { ui, request } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes';
import _ from 'lodash';
import qs from 'qs';

import { getReactSelectOptions, getOptions, getParentReactSelectOption } from '../../../helpers/react-select';
import Select, { components } from 'react-select';

import ChevronDown from './Images/ChevronDown.png';

const Wrapper = styled.div`
  display: flex;
  ${
    ({ mode }) => ({
      [Choices.Mode.Vertical]: css` flex-direction: column; `,
      [Choices.Mode.Horizontal]: css` flex-direction: row; `
    }[mode])
  }
`;

const Group = styled.ul`
  cursor: default;
  list-style: none;
  margin-top: 0px;
  margin: 0;
  padding-left: 0px;

  label {
    display: grid;
    position: relative;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
`;

const getRecursiveValues = (options) => {
  if (!options) {
    return [];
  }
  return (options || []).flatMap(c => [c.value, ...getRecursiveValues(c.children)])
};

const scan = (key, options) => _.reduce(options, (result, option) => {
  if (result) return result;
  if (option.value == key) return option;
  if (option.children) return scan(key, option.children);
  return result;
}, false);

const isAllDescendantsChecked = (value, contains) => {
  const allDescendants = (contains || []).flatMap(c => [c.value, ...getRecursiveValues(c.children)]);
  return allDescendants.every(k => (value || []).includes(k));
};

const getCheckboxValue = (value, key) => {
  return (value || []).includes(key);
};

const getCheckboxPartial = (value, contains) => {
  if (_.isEmpty(contains)) {
    return false;
  }
  const allDescendants = (contains || []).flatMap(c => [c.value, ...getRecursiveValues(c.children)]);
  const selectedDescendants = allDescendants.filter(k => (value || []).includes(k));

  // Return true if some, but not all, descendants are selected
  return selectedDescendants.length > 0 && selectedDescendants.length < allDescendants.length;
};

const Checkboxes = ({ name, value=[], onChange, children, subchecks, isDisabled, includeParentTree }) => {
  const anyChildren = _.some(children, (value) => value.children);

  return <Group>
    { _.map(children, (option) => {
      let { label, value: key, disabled } = option;
      const field = `${ name }[${ key }]`;

      const contains = option.children;

      if (isDisabled) disabled = true;

      const checkbox = <UI.Checkbox
        key={ field }
        name={ field }
        value={ getCheckboxValue(value, key) }
        partial={ getCheckboxPartial(value, contains) }
        onChange={ (checked) => {
          value = value || []
          if (checked) {
            if (!value.includes(key)) {
              value.push(key)
              if (!_.isEmpty(contains)) {
                value = _.uniq(_.concat(value, getRecursiveValues(contains)))
              }
            }
          } else {
            if (value.includes(key)) {
              const descendents = getRecursiveValues(contains);
              value = value.filter(k => k !== key && !descendents.includes(k))
            }
          }
          onChange(value)
        } }
        label={ label }
      />;

      return <li css={ [
        ( ( anyChildren || subchecks ) && !contains ) && css` margin-left: 24px; `,
        css` margin-bottom: 16px; `
      ] } >
        {
          contains
            ? <UI.Dropdown.Small title={ checkbox } >
                <div css={ css` margin-left: 32px; ` } >
                  <Choices
                    UI={ UI }
                    name={ key }
                    contents={ contains }
                    multiple
                    mode={ Choices.Mode.Vertical }
                    format={ Choices.Format.Array }
                    subchecks
                    includeParentTree={ includeParentTree }
                    value={ (value || []).filter(k => !_.isEmpty(scan(k, contains))) }
                    onChange={ (change) => {
                      change = change || [];
                      value = value || []
                      const existingValues = (value || []).filter(k => !_.isEmpty(scan(k, contains)));
                      const oldKeys = existingValues.filter(k => !change.includes(k))
                      const newKeys = change.filter(k => !existingValues.includes(k))
                      let valueWithChanges = _.concat(value.filter(k => !oldKeys.includes(k)), newKeys)
                      if (includeParentTree) {
                        if (change.length > 0) {
                          valueWithChanges.push(key);
                        } else {
                          valueWithChanges = valueWithChanges.filter(x => x !== key);
                        }
                      }
                      // We do not want to include the whole parent tree when one of the childs are selected, but we do want
                      // to handle the case when ALL the children are selected (the parent needs to be selected) or when not all
                      // of the children are selected (then the parent is not selected either).
                      else {
                        const descendents = getRecursiveValues(contains);
                        if (change.length > 0 && _.intersection(change, descendents).length === descendents.length) {
                          valueWithChanges.push(key);
                        } else {
                          valueWithChanges = valueWithChanges.filter(x => x !== key);
                        }
                      }
                      onChange(_.uniq(valueWithChanges));
                    } }
                  />
                </div>
              </UI.Dropdown.Small>
            : checkbox
        }
      </li>;
    }) }
  </Group>;
};

export const Radios = ({ name, value, onChange, mode, children, isDisabled }) => (
  <Group css={ css` margin-bottom: 0; ` }>
    { _.map(children, (option) => {
      let { label, value: key, disabled } = option;
      const field = `${ name }[${ key }]`;

      if (isDisabled) disabled = true;

      return <li key={ key } css={ css`
        ${
          {
            [Choices.Mode.Vertical]: css` padding-bottom: 0px; &:last-child { padding-bottom: 0px; } `,
            [Choices.Mode.Horizontal]: css` padding-right: 16px; `
          }[mode]
        }

        ${ disabled && css` opacity: 0.5; ` }

        align-items: first baseline;
        display: flex;

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #D1D1D1;
          border-radius: 10px;
          flex-shrink: 0;
          height: 20px;
          margin-left: 0px;
          margin-right: 10px;
          width: 20px;

          &:checked {
            background: radial-gradient(circle at center, ${Theme.colors.brand} 40%, #FFFFFF 40%, #FFFFFF 60%);
            border-color: ${Theme.colors.brand};
            height: 20px;
            width: 20px;
          }
        }

        label {
          position: relative;
          top: -4px;
        }

      ` }>
        <input
          id={ field }
          type="radio"
          name={ name }
          key={ field }
          value={ key }
          checked={ value == key }
          disabled={ disabled }
          title={ label }
          onChange={ () => onChange(key) }
        />
        <label htmlFor={ field } dangerouslySetInnerHTML={{ __html: label }}></label>
      </li>
    }) }
  </Group>
);

const MultiValueGroupComponent = (props) => {
  const parent = getParentReactSelectOption(props.selectProps.options, props.data);
  return <div className={ props.innerProps.className } >
    { parent && <strong>{ parent.label }: </strong> }{ props.data.label }
  </div>;
};

const Option = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data
  } = props;
  return (
    <div
      ref={ innerRef }
      css={ getStyles('option', props) }
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
      { ...innerProps }
      title={ data?.title }
    >
      { children }
    </div>
  );
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <img
      alt=""
      src={ChevronDown}
      css={css`
        margin: auto;
        width: 17px;
      `}
    />
  </components.DropdownIndicator>
);

export const SelectDropDown = ({
  name,
  value,
  onChange,
  options,
  disabled,
  placeholder,
  multiple,
  validated,
  compact
}) => (
  <Select
    key={name}
    isMulti={multiple}
    isClearable={multiple}
    isDisabled={disabled}
    placeholder={placeholder ? placeholder : undefined}
    styles={{
      control: (provided, state) => {
        let borderColor = state.isFocused ? Theme.colors.brand : "#585858";
        if (!validated) borderColor = "#940420";

        return ({
          ...provided,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: `${borderColor} !important`,
          boxShadow: 'none',
          borderRadius: 0
        })
      },
      valueContainer: (provided, state) => ({
        ...provided,
        padding: compact ? null : '10px 8px 9px',
        color: '#585858',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        width: 48,
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        marginTop: 0,
        borderWidth: 0,
        boxShadow: 'none !important',
        filter: 'drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.2))',
        zIndex: 9999999,
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: 0,
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        opacity: state.isDisabled ? 0.5 : 1,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        padding: '12px 11px',
        backgroundColor: state.isSelected ? '#E6E6E6' : '#FFF',
        transition: 'all 0.2s',
        ':hover': {
          backgroundColor: '#f0f0f0',
        },
      }),
      noOptionsMessage: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        padding: '14px 12px',
      }),
    }}
    components={{
      Option,
      MultiValueLabel: MultiValueGroupComponent,
      DropdownIndicator,
    }}
    value={value}
    onChange={onChange}
    options={options}
    isOptionDisabled={option => option.disabled}
  />
);

const arrayUnwrap = (value) => {
  if (typeof value === 'undefined') {
    return value;
  }
  if (value instanceof Array) {
    return value[0];
  }
  return value;
}

const Choices = (props) => {
  const {
    UI, name, onChange, value,
    multiple=false, validated=true, disabled=false,
    placeholder=false, autoDropdown=4, subchecks=false,
    responseHandler=null,
    query={}
  } = props;

  let { contents, mode=undefined, format=undefined, includeParentTree=true } = props;

  if (_.isFunction(contents)) {
    contents = contents();
  }

  if (_.isEmpty(contents)) contents = {};

  if (_.isString(contents)) {
    let [url, subquery = {}] = contents.split('?')
    subquery = qs.stringify(
      _.merge(qs.parse(subquery, { arrayFormat: 'brackets' }), query || {}), { arrayFormat: 'brackets' }
    )
    url = [url, subquery].filter(_.identity).join('?')
    return <Subschema>{[
      ui`Choices`(_.omit(props, ['Board', 'Contents', 'Subschema', 'UI', 'contents'])),
      request(url)
        .during({ disabled: true, placeholder: 'Loading options...' })
        .failure((error) => ({ disabled: true, placeholder: 'Error loading! Please try again' }))
        .success(({ data }) => ({ contents: responseHandler ? responseHandler(data) : data }))
    ]}</Subschema>;
  }

  if (typeof mode === 'undefined' && autoDropdown && Object.keys(contents).length >= autoDropdown) mode = Choices.Mode.Dropdown;
  if (typeof mode === 'undefined') mode = Choices.Mode.Vertical;
  if (typeof format === 'undefined') format = Choices.Format.Array

  contents = getOptions(contents)

  if (format === Choices.Format.Object) {
    throw new Error('Object format no longer valid')
  }

  // eslint-disable-next-line default-case
  switch (mode) {
    case Choices.Mode.Vertical:
    case Choices.Mode.Horizontal:
      if (multiple && !value) onChange([]);
      return <Wrapper mode={ mode } key={ name } >{
        multiple
          ? <Checkboxes name={ name } value={ value } onChange={ onChange } isDisabled={ disabled } subchecks={ subchecks } includeParentTree={ includeParentTree } >{ contents }</Checkboxes>
          : <Radios name={ name } value={ value } onChange={ onChange } mode={ mode } isDisabled={ disabled } >{ contents }</Radios>
      }</Wrapper>;
    case Choices.Mode.Dropdown:
      return (
        <SelectDropDown
          name={name}
          value={
            multiple
              ? (Array.wrap(value) || [])?.map(key => scan(key, contents))
              : scan(arrayUnwrap(value), contents)
          }
          onChange={choice =>
            onChange(
              multiple ? choice.map(option => option.value) : choice.value
            )
          }
          options={getReactSelectOptions(contents)}
          disabled={disabled}
          placeholder={placeholder}
          multiple={multiple}
          validated={validated}
        />
      );
  }

  return null;
};

Choices.Mode = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
  Dropdown: 'dropdown'
};

Choices.Format = {
  Object: 'object',
  Array: 'array'
}

export default Choices;
