import LocalizedStrings from 'react-localization';
import _ from 'lodash';
import TS from './ts';

const stringsFor = language => {
  const strings = new LocalizedStrings(TS, { logsEnabled: false });
  strings.setLanguage(language);
  return strings;
}

const makeT = (locale, verbose = process.env.NODE_ENV === 'development', localizedStrings = null) => {
  const strings = localizedStrings || stringsFor(locale);

  const getFunction =  (key,dict = null) => {
    let baseString = _.get(strings, key);
    const { default: defaultValue, ...interpolations } = dict || {};

    if(!dict || _.isUndefined(baseString))
      return baseString || defaultValue;

    Object.keys(interpolations).forEach(dictKey => {
      const r = new RegExp(`\\%\\{${dictKey}\\}`, "g")
      baseString = baseString.replace(r, interpolations[dictKey])
    });
    return baseString;
  };
  if (verbose) {
    return (key, dict) => {
      let result = getFunction(key, dict);

      if (_.isUndefined(result)) {
        return `MISSING: ${locale}.${key}`;
      }

      return result;
    };
  }
  return getFunction;

};

const sharedValidator = (zip, required, regex) => (
  !!((zip||'').match(regex) || (!required && !zip))
)

const usZipInfo = {
  field_name: 'zip',
  regex: /^\d{5}$/i,
  validator: (zip, required) => (
    sharedValidator(zip, required, usZipInfo.regex)
  )
};

const caZipInfo = {
  field_name: 'postal',
  regex: /[a-z]\d[a-z]\s?.{0,3}$/i,
  validator: (zip, required) => (
    sharedValidator(zip, required, caZipInfo.regex)
  )
};


const defaultZipInfo = {
  field_name: 'postal',
  // validator is just a precheck before we hit MDMS.validzip. doesn't gurantee the zip is valid
  // given all the varrying formats we're taking on, I'd prefer to just leave all that logic in the backend
  validator: (zip, required) => zip?.length > 3 || !required,
};

const getZipInfo = locale => {
  const region = _.last(locale.split('-'));
  switch (region) {
    case 'US':
      return usZipInfo;
    case 'CA':
      return caZipInfo;
    case 'SE':
    case 'DK':
    case 'LV':
    case 'FI':
    case 'LT':
    case 'EE':
    case 'GB':
    case 'DE':
    case 'PL':
      return defaultZipInfo;
    default:
      return null;
  }
};

const localeNumberFormat = locale => {
  if (locale === 'fr-CA') {
    return { thousandSeparator: ' ', decimalSeparator: ',' };
  }

  return { thousandSeparator: ',', decimalSeparator: '.' };
};

const raw_strings = stringsFor('en-US');


// when adding a locale, see config/application.rb (config.i18n.available_locales)
// we also rely on these being in specificity order (e.g. "en" before "en-NA" before "en-US")
const orderedLocales = [
  'en',
  'es',
  'cs',
  'da',
  'nl',
  'et',
  'fi',
  'fr',
  'de',
  'it',
  'lv',
  'lt',
  'no',
  'pl',
  'ru',
  'sv',
  'uk',
  'en-NA',
  'en-EU',
  'de-EU',
  'ru-EU',
  'fr-EU',
  'sv-EU',
  'fi-EU',
  'en-US',
  'es-US',
  'en-CA',
  'fr-CA',
  'es-MX',
  'en-BR',
  'pt-BR',
  'sv-SE',
  'fi-FI',
  'lt-LT',
  'pl-PL',
  'lv-LV',
  'de-DE',
  'et-EE',
  'nn-NO',
  'no-NO',
  'da-DK',
  'en-GB',
  'cs-CZ',
  'fr-FR',
  'it-IT',
  'nl-NL',
  'fr-BE',
  'nl-BE',
  'uk-UA',
  'es-ES',
  'en-IN',
  'en-JP',
  'ja-JP',
];

const localeSort = (localeA, localeB) => (
  orderedLocales.indexOf(localeA?.code) - orderedLocales.indexOf(localeB?.code)
);

// parent locales are any two letter locale is a parent of any other that matches
// we have a couple artificial ones too, "NA" is a parent to "CA" and "US" and "EU" is a parent to many EU countries
// on rails side we have a gem to find continent, here we'll just have to map
// we will likely need special cases to match up "language" that has a dash, like zh-Hans or various Cyrillic
// possibly splitting on last - or trying to pre-match some known language codes out
const parentLocales = _.mapValues(_.keyBy(orderedLocales), v => {
  if (v.length == 2)
    return [];

  const [lang, region] = v.split('-');
  const additional = [lang];
  let continent = '';
  switch (region) {
    case 'US':
    case 'CA':
    case 'MX':
      continent = 'NA';
      break;
    case 'BE':
    case 'CZ':
    case 'DE':
    case 'DK':
    case 'EE':
    case 'ES':
    case 'FI':
    case 'FR':
    case 'GB':
    case 'IT':
    case 'LT':
    case 'LV':
    case 'NL':
    case 'NO':
    case 'PL':
    case 'RU':
    case 'SE':
    case 'UA':
      continent = 'EU';
      break;
  }
  if (continent) {
    const probably = [lang, continent].join('-')
    if (orderedLocales.indexOf(probably) >= 0)
      additional.push(probably)
  }
  return additional;
});

// above object looks like this:
// 'en': [],
// 'en-NA': [ 'en' ],
// 'en-US': [ 'en', 'en-NA' ],

const isLocaleChild = (parentLocale, childLocale) => {
  if (!parentLocales[childLocale]) {
    return false;
  }

  return parentLocales[childLocale].indexOf(parentLocale) >= 0;
};

/*
 * Is userLocale either the same or a child of locale?
 * e.g. isLocaleChild('en', 'en-US') => true
 * @param {object} locale - The page's locale (parent)
 * @param {object} userLocale - The user's locale (detected preference)
 * @return {boolean} - True if the locale is a match for the user's locale
 */
export function isLocaleMatch(locale, userLocale) {
  if (!locale?.code || !userLocale?.code) {
    return false;
  }
  return (
    locale.code === userLocale.code ||
    isLocaleChild(locale.code, userLocale.code)
  );
}

// we have a lot of code that says country = code.split('-')[1], so international gets to be undefined
export const INTERNATIONAL = undefined;

export { raw_strings, makeT, stringsFor, getZipInfo, localeNumberFormat, localeSort, isLocaleChild, parentLocales };
