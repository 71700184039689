// Lists all the available actions

export const BYR_SET_ROOF_ID = 'BYR_SET_ROOF_ID';
// BYR Editor
export const BYR_EDITOR_CHANGE_API_DATA = 'BYR_EDITOR_CHANGE_API_DATA';
export const BYR_EDITOR_CHANGE_DATA = 'BYR_EDITOR_CHANGE_DATA';
export const BYR_EDITOR_CHANGE_ZIP = 'BYR_EDITOR_CHANGE_ZIP';
export const BYR_EDITOR_CHANGE_LEADS_EMAIL = 'BYR_EDITOR_CHANGE_LEADS_EMAIL';
export const BYR_EDITOR_CHANGE_STEP_ORDER = 'BYR_EDITOR_CHANGE_STEP_ORDER';
export const BYR_EDITOR_LOAD_CONFIGURATION = 'BYR_EDITOR_LOAD_CONFIGURATION';
export const BYR_EDITOR_SET_EDIT = 'BYR_EDITOR_SET_EDIT';
export const BYR_EDITOR_SET_LOADING = 'BYR_EDITOR_SET_LOADING';
export const BYR_EDITOR_SET_RECOMMENDED = 'BYR_EDITOR_SET_RECOMMENDED';
export const BYR_EDITOR_TOGGLE_VISIBILITY = 'BYR_EDITOR_TOGGLE_VISIBILITY';

// PYS Favorite
export const SHINGLES_FAVORITE_ADD = 'SHINGLES_FAVORITE_ADD';
export const SHINGLES_FAVORITE_REMOVE = 'SHINGLES_FAVORITE_REMOVE';
export const SHINGLES_FAVORITE_RESYNC = 'SHINGLES_FAVORITE_RESYNC';

// PYS Recently viewed
export const SHINGLES_ADD_RECENTLY_VIEWED = 'SHINGLES_ADD_RECENTLY_VIEWED';

// Wallbuilder
export const ENCLOSURE_WALLBUILDER_DISPATCH = "ENCLOSURE_WALLBUILDER_DISPATCH";
export const ENCLOSURE_WALLBUILDER_RESET = "ENCLOSURE_WALLBUILDER_RESET";
export const ENCLOSURE_WALLBUILDER_SET_VISIBILITY = "ENCLOSURE_WALLBUILDER_SET_VISIBILITY";
export const ENCLOSURE_WALLBUILDER_INTERACTION = "ENCLOSURE_WALLBUILDER_INTERACTION";
export const ENCLOSURE_FILTERS_RESET = "ENCLOSURE_FILTERS_RESET";
export const ENCLOSURE_FILTERS_INTERACTION = "ENCLOSURE_FILTERS_INTERACTION";

// Location
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const LOCATION_MODAL_TOGGLE = 'LOCATION_MODAL_TOGGLE';
export const CHANGE_USER_LOCATION = 'CHANGE_USER_LOCATION';

// Feature Flags
export const FEATURE_FLAG_DISABLE_KEY = 'FEATURE_FLAG_DISABLE_KEY';
export const FEATURE_FLAG_DISABLE_ALL = 'FEATURE_FLAG_DISABLE_ALL';

// CMS
export const CMS_JOIN = 'CMS_JOIN';
export const CMS_LEAVE = 'CMS_LEAVE';
export const CMS_SAVING = 'CMS_SAVING';
export const CMS_EDIT_CONTENT = 'CMS_EDIT_CONTENT';
export const CMS_EDIT_METADATA = 'CMS_EDIT_METADATA';
export const CMS_SET_CONTENTS = 'CMS_SET_CONTENTS';
export const CMS_APPLY_CONFLICT = 'CMS_APPLY_CONFLICT';
export const CMS_SYNC_ERROR = 'CMS_SYNC_ERROR';
export const CMS_SYNC_HELLO = 'CMS_SYNC_HELLO';
export const CMS_SYNC_SELECT_ITEM = 'CMS_SYNC_SELECT_ITEM';

// Fiberglass Calculator
export const PROPINK_FIBERGLASS_CALC_SET_PRICE = 'PROPINK_FIBERGLASS_CALC_SET_PRICE';
export const PROPINK_FIBERGLASS_CALC_SET_CALCULATIONS = 'PROPINK_FIBERGLASS_CALC_SET_CALCULATIONS';
export const PROPINK_FIBERGLASS_CALC_SET_RVALUE = 'PROPINK_FIBERGLASS_CALC_SET_RVALUE';

// Cellulose Calculator
export const PROPINK_CELLULOSE_CALC_SET_PARAM  = 'PROPINK_CELLULOSE_CALC_SET_PARAM';
export const PROPINK_CELLULOSE_CALC_SET_CALCULATIONS  = 'PROPINK_CELLULOSE_CALC_SET_CALCULATIONS';

// Spray Foam Calculator
export const PROPINK_SPRAYFOAM_SET_VARIANT = 'PROPINK_SPRAYFOAM_SET_VARIANT';

//Resources
export const GENERIC_LOCATOR_LOAD_DATA = 'GENERIC_LOCATOR_LOAD_DATA';
export const GENERIC_LOCATOR_SET_MAX_DISTANCE = 'GENERIC_LOCATOR_SET_MAX_DISTANCE';

// VideoMetadata
export const VIDEO_METADATA_UPDATE_DATA = 'VIDEO_METADATA_UPDATE_DATA';
