import React from 'react';
import { Provider } from 'react-redux';

import LocationModalController from './Popup/Modal';
import { store } from '../Store';

export const CrossingLocale = () => (
  <LocationModalController
    mismatched
    isSwitcher={false}
    postalRequired
    showZip
    showLocale
    locationModalOpen
  />
);

export default () => (
  <Provider store={store}>
    <CrossingLocale />
  </Provider>
);
