import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { connectLocation } from './LocationConnectors';
import { makeCountryOption, CountryPicker } from './Popup/CountryPicker';
import { makeT, isLocaleMatch, INTERNATIONAL } from './locales';
import userLocaleSort from './userLocaleSort';
import useDeviceBreakpoint from '../../hooks/use_device_breakpoint';
import Theme from '../../themes/index';
import Button from '../ComponentLibrary/oc-button';
import Icon from '../OC/oc-icon';

const BannerContainer = styled.div`
  background: ${'#FFF1E5'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  .error {
    color: #d65900;
    font-weight: 700;
    margin-right: 16px;
    padding: 0;
  }

  span {
    margin-right: 16px;
  }

  a {
    margin-right: 16px;
  }

  .banner-error {
    margin-left: 16px;
  }

  .country-picker-container {
    width: 250px;
    margin-right: 16px;
    margin-bottom: -18px;
  }

  .banner-text {
    display: flex;
    flex-direction: row;
    margin-right: 12px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    padding: 12px;
    .country-picker-container {
      width: 94%;
    }
  }
`;

const Close = styled.span`
  font-size: 36px;
  &:hover {
    color: ${Theme.colors.brand};
    cursor: pointer;
  }
`;

const OTHER = 'OTHER_COUNTRY';

const navigateToLocations = () => {
  window.location.href = '/locations-languages';
};

const CrossingLocaleBanner = props => {
  const { availableLocales, locale, userLocale, changeUserLocation } = props;
  const sortedLocales = availableLocales
    .slice()
    .sort(userLocaleSort(userLocale));
  const mismatched = !isLocaleMatch(locale, userLocale);
  const [hasUserClosed, setHasUserClosed] = useState(
    sessionStorage.getItem('hasUserClosedCrossingLocaleBanner') === 'true'
  );
  const handleClose = () => {
    sessionStorage.setItem('hasUserClosedCrossingLocaleBanner', 'true');
    setHasUserClosed(true);
  };
  const isVisible = mismatched && !hasUserClosed;

  const [selectedLocale, setSelectedLocale] = useState(
    sortedLocales.find(l => isLocaleMatch(l, userLocale)) || sortedLocales[0]
  );
  const selectedCountry = selectedLocale.other
    ? OTHER
    : selectedLocale.code.split('-')[1] || INTERNATIONAL;
  const setSelectedCountry = countryCode => {
    const nextLocale = sortedLocales.find(
      l => (l.code.split('-')[1] || INTERNATIONAL) === countryCode
    );
    if (nextLocale) {
      setSelectedLocale(nextLocale);
    } else {
      // hack to make "other" country option
      setSelectedLocale({ ...userLocale, other: true });
    }
  };

  const selectedLocaleCode = selectedLocale.code;
  const selectedT = makeT(selectedLocaleCode);
  const userT = makeT(userLocale.code);

  const handleContinue = () => {
    if (!selectedLocaleCode) {
      throw new Error('Selected locale not found');
    }
    if (selectedLocale.other) {
      navigateToLocations();
    } else {
      changeUserLocation({ locale: selectedLocale });
    }
  };

  const countryOptions = {};
  sortedLocales.forEach(l => {
    const cCode = l.code.split('-')[1] || INTERNATIONAL;
    if (!countryOptions[cCode]) {
      countryOptions[cCode] = makeCountryOption(l, true);
    }
  });
  countryOptions[OTHER] = {
    value: OTHER,
    label: userT('crossing_locale.other_country'),
  };

  const { isPhone } = useDeviceBreakpoint();
  let iconLeft = (
    <Icon type="exclamation-triangle" className="error banner-error" />
  );
  let closeLast = <Close onClick={handleClose}>&times;</Close>;
  let afterText = null;
  if (isPhone) {
    afterText = (
      <>
        {iconLeft}
        {closeLast}
      </>
    );
    iconLeft = null;
    closeLast = null;
  }

  return (
    isVisible && (
      <BannerContainer>
        {iconLeft}
        <div className="banner-text">
          {selectedT(`crossing_locale.for_local_information`)}
          {afterText}
        </div>
        <div className="country-picker-container">
          <CountryPicker
            options={Object.values(countryOptions)}
            value={countryOptions[selectedCountry]}
            setCountry={setSelectedCountry}
            data-track="change-zip"
            compact
          />
        </div>

        <Button color="white" onClick={handleContinue} data-track="change-zip">
          {selectedT(`crossing_locale.continue`)}
        </Button>
        {closeLast}
      </BannerContainer>
    )
  );
};

export const localeType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  countryName: PropTypes.string,
  flag: PropTypes.string,
  languageName: PropTypes.string,
});

CrossingLocaleBanner.propTypes = {
  availableLocales: PropTypes.arrayOf(localeType).isRequired,
  locale: PropTypes.oneOf([localeType, null]),
  userLocale: PropTypes.oneOf([localeType, null]),
  changeUserLocation: PropTypes.func.isRequired,
};

CrossingLocaleBanner.defaultProps = {
  locale: null,
  userLocale: null,
};

export default connectLocation(CrossingLocaleBanner);
