import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { connectLocation } from './LocationConnectors';
import LocationModalController from './Popup/Modal';
import { isLocaleMatch } from './locales';
import flagGlobe from '../../../assets/images/components/language-picker/globe.png';
import Theme from '../../themes/index';
import Icon from '../OC/oc-icon';
import { store } from '../Store';

const SwitcherContainer = styled.div`
  background: ${props => (props.error ? '#FFF1E5' : 'transparent')};
  display: flex;
  padding: ${props => (props.error ? '16px' : '0')};

  .error {
    color: #d65900;
    font-weight: 700;
    margin-right: 16px;
    padding: 0;
  }
`;

const localeButtonCSS = css`
  align-items: center;
  -webkit-appearance: button;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin-left: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-transform: none;

  &:hover {
    color: ${Theme.colors.brand};
  }

  > span {
    font-size: 12px;
    font-weight: bold;
    margin: 0 5px;
  }

  .locale-language-code {
    font-weight: 600;
    text-transform: uppercase;
  }

  i {
    font-size: 10px;
  }
`;

const SwitcherButtonWithFlag = connectLocation(
  ({
    className,
    zip,
    locale: { flag, countryName, languageName, code },
    openLocationModal,
    showZip,
  }) => {
    const flagIcon = code.match(/(NA|EU)$/) ? flagGlobe : flag;
    const zipOrRegion = zip && zip.length > 0 ? zip : countryName;

    return (
      <button
        onClick={openLocationModal}
        className={className}
        data-track="open-modal"
        data-track-modal-name="Region Selector"
        type="button"
      >
        <img src={flagIcon} width="20" alt="" />
        <span className="sr-only">{showZip && 'zipcode, '}language</span>
        <span>
          {zipOrRegion && `${zipOrRegion} -`} {languageName}
        </span>
        <i aria-hidden="true" className="fa fa-chevron-down" />
      </button>
    );
  }
);

const SwitcherButtonWithoutFlag = connectLocation(
  ({ className, locale: { languageName, code }, openLocationModal }) => {
    const [languageCode, _] = code.split('-');

    return (
      <button
        onClick={openLocationModal}
        className={className}
        data-track="open-modal"
        data-track-modal-name="Region Selector"
        type="button"
      >
        <span className="locale-language-code">{languageCode}</span>
        <span key={languageCode}>{languageName}</span>
        <i aria-hidden="true" className="fa fa-chevron-down" />
      </button>
    );
  }
);

export const LocationSwitcher = connectLocation(
  ({
    skipWrapper,
    showLocale = true,
    userLocale,
    locale,
    postalRequired = true,
    hideFlag = false,
  }) => {
    const SwitcherButton = hideFlag
      ? SwitcherButtonWithoutFlag
      : SwitcherButtonWithFlag;
    const mismatched = !isLocaleMatch(locale, userLocale);

    return (
      <SwitcherContainer
        className={skipWrapper ? '' : 'oc-region-selector flags'}
        error={mismatched}
      >
        {mismatched && (
          <span className="error">
            <Icon type="exclamation-triangle" />
          </span>
        )}
        <LocationModalController
          mismatched={mismatched}
          isSwitcher
          showLocale={showLocale}
          postalRequired={postalRequired}
        />
        <SwitcherButton css={localeButtonCSS} showZip={postalRequired} />
      </SwitcherContainer>
    );
  }
);

const ProvidedLocationSwitcher = ({
  skipWrapper,
  showLocale,
  postalRequired = true,
  hideFlag = false,
}) => (
  <Provider store={store}>
    <LocationSwitcher
      skipWrapper={skipWrapper}
      showLocale={showLocale}
      postalRequired={postalRequired}
      hideFlag={hideFlag}
    />
  </Provider>
);

ProvidedLocationSwitcher.propTypes = {
  skipWrapper: PropTypes.bool,
  showLocale: PropTypes.bool.isRequired,
  postalRequired: PropTypes.bool.isRequired,
  hideFlag: PropTypes.bool,
};

ProvidedLocationSwitcher.defaultProps = {
  skipWrapper: false,
  hideFlag: false,
};

export default ProvidedLocationSwitcher;
