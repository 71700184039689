import styled from '@emotion/styled';
import { ui, when, s } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import moment from 'moment';

import GenericContentSearch from './helpers/GenericContentSearch';
import itemListResponse, {
  generateFilterOptions,
} from './helpers/item_list_response';
import pathToContent from './helpers/path_to_content';
import cmsApi from '../../../../cms_api';
import { expandRef, wrapRef } from '../../../../data';
import useReference from '../../../../hooks/use_reference';
import Theme from '../../../../themes/index';
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';
import RichText from '../../../OC/PageBuilder/RichText';
import Text from '../../../OC/PageBuilder/utilities/Text';
import FeaturedDocument from '../../../OC/oc-featured-document';
import FileBadge from '../../../OC/oc-file-badge';
import Icon from '../../../OC/oc-icon';
import getFileBasename from '../../../helpers/get_file_basename';
import getFileSize from '../../../helpers/get_file_size';
import getFormatFromMime from '../../../helpers/get_format_from_mime';
import { connectLocation } from '../../../location/LocationConnectors';
import {
  digChoicesFromData,
  digItemAttributesFromData,
} from '../../Library/AttributeSet';

const refSearchMetadata = (startOpen, resultMapper) => ({
  startOpen,
  label: 'Document',
  dataset: 'document_builder',
  set: (value, path) => {
    // TODO: handle empty/null value?
    const ref = wrapRef('Cms::Content', value.ref);
    expandRef(ref).then(result => resultMapper(result, path))
    return ref;
  },
  get: (value) => {
    const { results, error, loading } = useReference(value);
    if (loading) {
      return <i>Loading...</i>
    } else if (error) {
      return <span>{ error }</span>
    }
    return results;
  }
});

// Styled Components
const ResultItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Container = styled.a`
  display: inline-block;
  position: relative;
  text-decoration: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    > * {
      color: initial;
    }

    .document-heading {
      color: ${Theme.colors.brand};
    }
  }
`;

const Heading = styled.strong`
  color: #000000;
  display: inline-block;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
`;

const DocumentDetails = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  position: relative;

  span {
    color: ${Theme.colors.greyShade1};
    margin-right: 8px;
  }
`;

const DetailIcon = styled(Icon)`
  &.detail-icon {
    color: ${props => props.color || "#000000"};
  }
`;

const Description = styled.div`
  & > div > div {
    margin: 0;
  }

  p {
    margin: 8px 0;
  }
`;

const AttributeList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 14px;
  gap: 8px 16px;

  ${props => props.splitLayout && `
    @media(min-width: 768px) {
      flex-direction: row;

      > span {
        @media(min-width: 768px) {
          width: calc(50% - 8px);
        }
      }
    }
  `}
`;

const Attribute = styled.span`
  align-item: center;
  color: ${Theme.colors.textGrey};
  display: flex;
  font-size: 14px;
  position: relative;
  width: 100%;

  strong {
    margin-right: 8px;
  }
`;

const EmailLink = styled.a`
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  font-size: 19px;
  padding: 24px;
`;

export const addOriginToUrl = url => {
  if (!url) return '';

  const { origin } = window.location;
  if (url.startsWith('http')) return url;

  return `${origin}${url}`;
};

const getDocFilename = (result) => {
  if (!result) return '';

  let url;
  switch (result?.contents?.type) {
    case "dms":
      url = result?.contents.dms?.url;
      break;
    case "upload":
      url = result?.contents?.document?.url;
      break;
    default:
      url = result?.external?.url;
  }

  return url ? getFileBasename(url) : '';
};

const getDocFormat = (result) => {
  if (!result) return '';

  let mime;
  switch (result?.contents?.type) {
    case "dms":
      mime = result?.contents?.dms?.mime_type;
      break;
    case "upload":
      mime = result?.contents?.document?.mime_type;
      break;
    case "external":
      return "external";
    default:
      null;
  }

  return mime ? getFormatFromMime(mime) : '';
};

const getDocDescription = (result) => {
  if (!result) return '';

  return result.metadata?.settings?.attributes?.short_description;
}

const getDocTitle = (result) => {
  if (!result) return '';

  return result?.metadata?.settings?.attributes?.title || result?.name;
}

const getDocUrl = (result) => {
  if (!result) return '';

  return addOriginToUrl(pathToContent(result));
};

const getDocSize = (result) => {
  const sizeBytes = result?.contents?.type === "dms" ? result.contents?.dms?.size_bytes : result?.contents?.document?.size_bytes
  return getFileSize(sizeBytes);
}

const getDocPublishDate = (result) => {
  const publishDate = result?.contents?.type === 'dms'
      ? result.contents?.dms?.publish_date || result.start_at
      : result.start_at;
  if (!publishDate) return null;

  return moment(publishDate, 'YYYY-MM-DDTHH:mm:ssZ')?.format(
    moment.localeData().longDateFormat('L')
  );
};

const DocBuilderResultBlock = connectLocation(({ t, attributes, ...result }) => {
  const title = getDocTitle(result);
  const docUrl = getDocUrl(result);
  const docFormat = getDocFormat(result);
  const fileTypes = {
    doc: { icon: "file-word-o", label: "DOC", color: "#00688C" },
    docx: { icon: "file-word-o", label: "DOCX", color: "#00688C" },
    dwg: { icon: "file-o", label: "DWG", color: "#000000" },
    external: { icon: "external-link", label: "External Link", color: "#000000" },
    pdf: { icon: "file-pdf-o", label: "PDF", color: "#CE1126" },
    rvt: { icon: "file-o", label: "RVT", color: "#000000" },
    xls: { icon: "file-excel-o", label: "XLS", color: "#336C40" },
    xlsx: { icon: "file-excel-o", label: "XLSX", color: "#336C40" },
  };
  const docFormatIcon = fileTypes[docFormat]?.icon || "file-o";
  const docFormatLabel = fileTypes[docFormat]?.label || docFormat?.toUpperCase() || "";
  const docFormatColor = fileTypes[docFormat]?.color;
  const size = getDocSize(result);
  const docLanguageCode = result?.metadata?.settings?.attributes?.language;
  const languageText = docLanguageCode ? t(`builder.languages.${docLanguageCode}`) : null;
  const short_description = getDocDescription(result);
  const publishDate = getDocPublishDate(result);
  const mailToSubject = t(`builder.documents.mailto.subject`, { site_name: PB_SITE_NAME });
  const mailToBody = t(`builder.documents.mailto.body`, { site_name: PB_SITE_NAME });

  const infoItems = (attributes || []).map(attribute => {
    const { label, field, type, of } = attribute;

    if (!label) {
      return null;
    }

    const resultValue = _.get(result, field);
    switch (type) {
      case 'choices':
        return {
          label,
          value: _.sortBy(Array.wrap(resultValue), (a, b) => {
            const keys = Object.keys(of || {});
            return keys.indexOf(a);
          }).map(v => of?.[v]?.label || v)
        };
      default:
        return { label, value: resultValue?.toString() };
    }
  }).filter(x => x.value?.length > 0);

  const infoItemComponents = infoItems.map(infoItem => (
    <Attribute><strong>{infoItem.label}</strong> {Array.wrap(infoItem.value).join(', ')}</Attribute>
  ));

  return (
    <ResultItem>
      <Container
        data-track="file-download"
        data-track-doc-type="Document Builder"
        data-track-element-location="search results"
        data-track-file-name={ getDocFilename(result) }
        href={ pathToContent(result) }
      >
        <Heading className="document-heading">
          <Text content={title} />
        </Heading>

        <DocumentDetails>
          {(docFormat || size) &&
            <>
              {docFormat && <DetailIcon className="detail-icon" color={docFormatColor} type={docFormatIcon} />}
              <span>{`${docFormatLabel} ${size}`.trim()}</span>
            </>
          }

          {languageText &&
            <>
              <DetailIcon className="detail-icon" type="language" />
              <span>{languageText}</span>
            </>
          }

          {publishDate &&
            <>
              <DetailIcon className="detail-icon" type="calendar" />
              <span>{publishDate}</span>
            </>
          }
        </DocumentDetails>

        {result.showDescription && short_description &&
          <Description>
            <RichText content={ short_description } />
          </Description>
        }

        <AttributeList splitLayout={infoItemComponents.length > 3}>
          { infoItemComponents }
        </AttributeList>
      </Container>
      {result.showEmailDocument &&
        <EmailLink href={`mailto:?subject=${mailToSubject}&body=${mailToBody}%0A%0A${docUrl}`}>
          <Icon type="envelope-o" />
        </EmailLink>
      }
    </ResultItem>
  )
});

const searchFields = [
  ['metadata', 'settings', 'attributes', 'keywords'],
  ['metadata', 'settings', 'attributes', 'title'],
];

const docListItemListResponse = (items, attributes, showFilters, enabledFilters, showEmailDocument, showDescription, allFilters) => {
  let results = items;
  if (items && !Array.isArray(items)) {
    results = items.results;
  }

  return itemListResponse(
    {
      items: results,
      filters: generateFilterOptions(showFilters, enabledFilters, allFilters, null),
      Component: (props) => <DocBuilderResultBlock attributes={attributes} showEmailDocument={showEmailDocument} showDescription={showDescription} {...props} />,
      searchFields,
      enableSearch: true,
    },
  )
}

const mapping = {
  SpecificationsAndLiterature: {
    availableIn: ['www.paroc.com', 'www.vidawool.com'],
    name: 'Document Builder',
    meta: {
      type: ui`Choices`.of({
        document: 'Document Builder',
        link: 'Link'
      })({
        label: 'Type',
        default: 'document',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink
      }),
      document: ui`Search`({
        ...refSearchMetadata(when`../document`.isnt.present.then(true).otherwise(false), x => x),
        visible: when`../type`.is.equal.to('document')
      }),
      link: ui`Url`({
        label: 'Document Link',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink,
        visible: when`../type`.is.equal.to('link')
      }),
      featured: ui`Switch`({
        label: 'Featured?',
        visible: when`../document`.is.present
      })
    },
  },
  RichText: {
    availableIn: ['www.paroc.com'],
    name: 'Document Builder',
    meta: {
      document: ui`Search`(refSearchMetadata(true, x => x))
    },
  },
  Accordion: {
    availableIn: ['www.paroc.com', 'www.vidawool.com'],
    name: 'Document Builder',
    old_view: async ({ categories } = {}) => {
      return Promise.all((categories || []).map(({ documents = [], ...rest }) => {
        return Promise.all(documents.map(document => {
          if (!document) return null;
          if (document.type === 'link') return document;
          if (!document.document) return null;
          return expandRef(document.document)
        })).then(expandedDocuments => {
          return {
            ...rest,
            documents: documents.map((d, i) => ({ ...d, document: { ...d.document, __data: expandedDocuments[i] } })).filter(Boolean).filter((d) => d.document.__data)
          }
        })
      })).then(result => result.filter(Boolean));
    }
  },
  ItemList: {
    availableIn: ['www.paroc.com', 'www.owenscorning.com', 'www.vidawool.com'],
    name: 'Document Builder',
    meta: async () => {
      const query = {
        filter: {
          type: 'Cms::Content::AttributeSet',
          name: 'Document Attributes',
          route: '/',
        },
        fields: {
          '*': 'contents,metadata'
        }
      }
      const data = await cmsApi.get_single_for_language(
        query,
        // eslint-disable-next-line no-undef
        Board.build.language
      );
      const hasFilters = !!data;
      const filterChoices = hasFilters ? await digChoicesFromData('metadata.settings.attributes', data) : null;
      const taxonomies = hasFilters ? Object.fromEntries(Object.entries(filterChoices).map(([key, entry]) => [key, entry.label])) : {}
      const filters = hasFilters ? ui`Form`.of(
        Object.fromEntries(Object.entries(filterChoices).map(([key, { of, ...entry }]) => ([
          key,
          ui`Choices`.of(of)({ ...entry, multiple: true, includeParentTree: false })
        ])))
      ) : null;
      const defaultEnabledFilterKeys = Object.keys(taxonomies);
      const enabledFilters = hasFilters ? ui`ChoicesDraggable`.of(taxonomies)({
        // ** here is where we need to add the filter UI type
        label: 'Filters',
        visible: when`../showFilters`.is.equal.to(true).then(true).otherwise(false),
        default: {
          enabledFilters: defaultEnabledFilterKeys,
          orderedList: Object.keys(taxonomies).map(k => ({[k]: defaultEnabledFilterKeys.indexOf(k) >= 0}))
        },
      }) : null
      const itemAttributeOptions = digItemAttributesFromData(data);
      const itemAttributes =
        itemAttributeOptions.length > 0
          ? ui`Choices`.of(itemAttributeOptions)({
              label: 'Item Attributes',
              sublabel: 'Select Item Attributes to Display:',
              mode: ui`Choices/Mode/Vertical`,
              multiple: true,
              tip: 'If more than three items are selected, they will shift to two columns.'
            })
          : null;
      return {
        source: ui`Choices`.of({
          'all': 'All Items',
          'filtered': 'Filtered',
          'specific': 'Select Specific Items'
        })({
          label: 'Select Structure',
          default: 'all',
          mode: ui`Choices/Mode/Dropdown`
        }),
        filters: ui`List/Item`.of(filters)({
          standalone: true,
          title: 'Document Attributes',
          label: 'Filters',
          visible: when`../source`.is.equal.to('filtered')
        }),
        items: ui`List`.of({
          item: ui`Search`({
            startOpen: when`../item`.isnt.present.then(true).otherwise(false),
            label: 'Document',
            dataset: 'document_builder',
            set: (value, path) => {
              // TODO: handle empty/null value?
              const ref = wrapRef('Cms::Content', { type: 'Document', id: value.id })
              expandRef(ref).then(result => {
                //Board.Change(result.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'proper_name'));
              })
              return ref;
            },
            get: (value) => {
              const { results, error, loading } = useReference(value);
              if (loading) {
                return <i>Loading...</i>
              } else if (error) {
                return <span>{error}</span>
              }
              return results;
            }
          }),
        })({
          singular: 'Document',
          title: 'proper_name',
          label: 'Documents',
          visible: when`../source`.is.equal.to('specific').then(true).otherwise(false)
        }),
        showFilters: ui`Switch`({
          label: 'Filter Pane',
          default: hasFilters,
          disabled: !hasFilters,
        }),
        [s._]: ui`Tip`.of(`Cannot find AttributeSet <b>Document Attributes</b> in a matching language`)({
          visible: !hasFilters,
        }),
        [s._]: ui`Tip`.of('If the item list you wish to display is to be very long, we recommend turning on the Filter Side Pane to allow users to filter down the results to their needs.')({
          visible: hasFilters,
        }),
        enabledFilters,
        showDescription: ui`Switch`({
          label: 'Show Item Description',
          default: false
        }),
        showEmailDocument: ui`Switch`({
          label: 'Show Email Document Button',
          default: false
        }),
        itemAttributes
      }
    },
    view: (data = null) => {
      const { data: items, meta: { parameters, filters, attributes } } = (data || { meta: {} });
      const { /*productSource, filters, items=[],*/ showFilters, enabledFilters, showEmailDocument, showDescription } = parameters || {};
      return docListItemListResponse(items, attributes, showFilters, enabledFilters, showEmailDocument, showDescription, filters || {})
    }
  },
  // ProductsInSolution: {
  //   availableIn: ['www.owenscorning.com'],
  //   name: 'DMS Document',
  //   fetch_data: async ({ items = [] }) => {
  //     if (_.isEmpty(items)) return;
  //
  //     const categories = items.map(async (x) => {
  //       if (!x?.product) return;
  //
  //       const product = await expandRef(x?.product);
  //       if (!product) return;
  //
  //       // For ProductsInSolution, documents now keep coming from the usual product setting path, but we also
  //       // want to return the visibility for these that might be located within the product->documents path
  //       return {
  //         productCategories: _.get(product, ['metadata', 'settings', 'documents', 'categories']),
  //         documentsVisibility: _.get(x, ['documents']) || []
  //       };
  //     });
  //
  //     return Promise.all(categories);
  //   }
  // },
  MediaObjectSet: {
    availableIn: ['www.paroc.com', 'www.vidawool.com'],
    name: 'Document Builder',
    meta: {
      items: ui`List`.of({
        document: ui`Search`(refSearchMetadata(false, x => x)),
      })({
        singular: 'Document',
        title: 'title',
        label: 'Documents',
      })
    },
    old_view: async ({ items } = {}) => {
      return Promise.all((items||[]).map(({ title, description, prehead, type, document }) => {
        if (!document) return null;
        return (document.__data ? Promise.resolve(document.__data) : expandRef(document)).then(result => {
          let mime_type = null;
          let size_bytes = null;
          let url = null;
          if (result.type === 'link' && result.document?.data) {
            mime_type = result.document.data.mime_type
            size_bytes = result.document.data.size_bytes
            url = result.document.data.url
          } else if (['upload', 'dms'].includes(result?.contents?.type) ) {
            mime_type = getDocFormat(result)
            size_bytes = getDocSize(result)
            url = pathToContent(result)
          }
          return {
            prehead: prehead,
            imgSize: 'document',
            aspectRatio: '0.77',
            image: result?.thumb ? {
              alt: result?.metadata?.settings?.attributes?.title || result?.title,
              file: result?.contents?.thumbnail || result?.thumb?.url
            } : null,
            url,
            heading: result?.metadata?.settings?.attributes?.title || title,
            text: result?.metadata?.settings?.attributes?.short_description || description,
            target: '_blank',
            linkText: (mime_type || size_bytes) ? <FileBadge extension={ mime_type ? getFormatFromMime(mime_type) : null } sizeBytes={ size_bytes } /> : '',
            analytics: {
              ...(result?.analytics || {}),
              'element-location': "main section text link"
            }
          };
        })
      }).filter(Boolean));
    }
  },
  CtaDocumentSet: {
    availableIn: ['www.paroc.com', 'www.vidawool.com'],
    name: 'Document Builder',
    meta: {
      item: ui`Form`.of({
        document: ui`Search`(refSearchMetadata(false, (x) => x)),
      })({
        singular: 'Document',
        title: 'title',
        label: 'Documents',
      })
    },
    old_view: async ({ item } = {}) => (await expandRef(item?.document))
  },
  Search: GenericContentSearch({
    search: {
      name: 'Document Builder',
      type: 'Document',
      preview: result => {
        const shortDescription = getDocDescription(result);
        return (
          result && (
            <FeaturedDocument
              title={null}
              description={
                <div style={{ marginTop: '-16px' }}>
                  <b style={{ fontSize: '16px' }}>Name: {result.name}</b>
                  <br />
                  <div style={{ fontSize: '12px' }}>
                    Title: {getDocTitle(result)}
                    <br />
                    Description: <RichText content={shortDescription} />
                    <br />
                    Doc Type: {getDocFormat(result)}
                    <br />
                    Language: {result.language_iso_code}
                  </div>
                </div>
              }
              url={pathToContent(result)}
              thumbUrl={result.contents?.thumbnail}
              hideBadge
            />
          )
        );
      },
    },
  }).Search,
}

export default mapping;
