import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import CrossingLocaleBanner from './CrossingLocaleBanner';
import CrossingLocaleModal from './CrossingLocaleModal';
import { connectLocation } from './LocationConnectors';
import { isLocaleMatch } from './locales';
import { store } from '../Store';

/*
 * top level component for CrossingLocale (flow for when a page has a locale that doesn't agree with the user's negotiated locale)
 * not to be confused with the CrossingLocale embeddable widget in components/Widgets/CrossingLocale
 */
export const CrossingLocale = connectLocation(
  ({ locale, userLocale, zip, postalRequired, availableLocales }) => {
    const mismatched = !isLocaleMatch(locale, userLocale);
    const zipNeeded = postalRequired ? !zip || zip === '' : false;

    if ((availableLocales?.length || 0) < 1) {
      return null;
    }
    if (!mismatched && !zipNeeded) {
      return null;
    }

    if (zipNeeded) {
      return <CrossingLocaleModal />;
    }

    return <CrossingLocaleBanner />;
  }
);

const ProvidedCrossingLocale = ({ postalRequired }) => (
  <Provider store={store}>
    <CrossingLocale postalRequired={postalRequired} />
  </Provider>
);

ProvidedCrossingLocale.propTypes = {
  postalRequired: PropTypes.bool.isRequired,
};

export default ProvidedCrossingLocale;
