import React from 'react';

import ProductResult from './ProductResult';
import {
  outsideAirRValue,
  osbRValue,
  gypsumRValue,
  insideAirRValue,
} from './constants';
import { useRValueCalculator } from './context';
import { ResultsContainer } from './styled_components';
import joinElements from '../../utilities/joinElements';

function renderRValue(number, decimalPlaces = 0) {
  if (!number || Number.isNaN(number)) {
    return '--';
  }
  return `R-${Math.round(number * 10 ** decimalPlaces) / 10 ** decimalPlaces}`;
}

const ResultsTable = () => {
  const {
    studWoodText,
    studSpacingText,
    cavityRValue,
    exteriorRValue,
  } = useRValueCalculator();

  return (
    <table>
      <tr>
        <td>Stud-Wood</td>
        <td>{studWoodText}</td>
      </tr>
      <tr>
        <td>Stud Spacing</td>
        <td>{studSpacingText}</td>
      </tr>
      <tr>
        <td>Exterior Insulation R-Value</td>
        <td>{renderRValue(exteriorRValue)}</td>
      </tr>
      <tr>
        <td>Cavity Insulation R-Value</td>
        <td>{renderRValue(cavityRValue)}</td>
      </tr>
    </table>
  );
};

const Assumptions = () => (
  <div className="assumptions">
    <p>This calculation assumes the following:</p>
    <ul>
      <li>Outside air film {outsideAirRValue}</li>
      <li>1/2" OSB {osbRValue}</li>
      <li>1/2" gypsum {gypsumRValue}</li>
      <li>Interior air film {insideAirRValue}</li>
      <li>R-Values are rounded to the nearest whole number</li>
    </ul>
  </div>
);

const ProductResults = () => {
  const { productResults } = useRValueCalculator();
  if (!productResults?.length) {
    return null;
  }

  return (
    <>
      <hr className="divider" />
      <h3>Products</h3>
      {joinElements(
        productResults.map(
          ({ route, description, image, name, prehead, id }) => (
            <ProductResult
              key={id}
              route={route}
              description={description}
              image={image}
              name={name}
              prehead={prehead}
            />
          )
        ),
        <hr className="divider" />
      )}
    </>
  );
};

const Results = () => {
  const { totalRValue, scrollToRef } = useRValueCalculator();
  return (
    <ResultsContainer>
      <h3 ref={scrollToRef}>Results</h3>
      <ResultsTable />
      <h4>TOTAL WALL R-VALUE</h4>
      <h2 className="pink">{renderRValue(totalRValue, 1)}</h2>
      <hr className="divider" />
      <Assumptions />
      <ProductResults />
    </ResultsContainer>
  );
};

export default Results;
