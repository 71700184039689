/* eslint-disable no-param-reassign */
import useWindowSize from './use_window_size';

const PHONE = { name: 'phone', minWidth: 768 };
const TABLET = { name: 'tablet', minWidth: 1200 };
const DESKTOP = { name: 'desktop', minWidth: 9999 };

const BREAKPOINTS = [PHONE, TABLET, DESKTOP];

BREAKPOINTS.forEach(breakpoint => {
  breakpoint.isPhone = breakpoint.name === PHONE.name;
  breakpoint.isTablet = breakpoint.name === TABLET.name;
  breakpoint.isDesktop = breakpoint.name === DESKTOP.name;
});

// simple hook to tell if the screen is a phone, tablet, or desktop,
// based on the width of the screen
// the other approach would be to use css media queries
// e.g. window.matchMedia('(hover: none) and (pointer: coarse)')
// the advantage of this is that we can tell 'tablet' from 'phone'

export function getDeviceBreakpoint(width) {
  if (!width) {
    return BREAKPOINTS[0];
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const breakpoint of BREAKPOINTS) {
    if (width < breakpoint.minWidth) {
      return breakpoint;
    }
  }
  return BREAKPOINTS[BREAKPOINTS.length - 1];
}

export default function useDeviceBreakpoint() {
  const windowSize = useWindowSize();
  return getDeviceBreakpoint(windowSize.width);
}
