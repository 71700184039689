function isValidUrl(text) {
  try {
    // eslint-disable-next-line no-unused-vars
    const url = new URL(text);
  } catch (_) {
    return false;
  }
  return true;
}

const localePrefixRegex = /^\/?[a-z]{2}(-[a-z]{2})?\b/i;
export function stripLocalePrefix(path) {
  if (typeof path?.match === 'function' && path?.match(localePrefixRegex)) {
    return path.replace(localePrefixRegex, '');
  }
  return path;
}

export function PrefixWithLocale(url, locale) {
  // return if it's an absolute path (an absolute path shouldn't be localized)
  if (isValidUrl(url)) return url;

  let code;
  switch (typeof locale) {
    case 'string':
      code = locale.toLowerCase();
      break;
    case 'object':
      code = locale.code.toLowerCase();
      break;
    default:
      return url;
  }

  let path = stripLocalePrefix(url);
  if (path === '/') {
    path = '';
  }

  return `/${code}${path}`;
}
