import styled from '@emotion/styled';
import Theme from '../../../themes/index';
import PropTypes from 'prop-types';

// Components
import BaseInput from './oc-input-base';

const TextInput = ({
  className = "",
  defaultValue,
  disabled = false,
  hidden = false,
  error,
  helperText,
  infoFunction,
  label,
  maxLength,
  multiline = false,
  name,
  themeColor = Theme.colors.brand,
  onBlur,
  onChange,
  placeholder,
  required = false,
  rows = 3,
  touched = false,
  valid = true,
  errorIcon = null,
  value
}) => {
  return (
    <BaseInput
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      helperText={helperText}
      id={name}
      infoFunction={infoFunction}
      inputType={hidden ? "hidden" : (multiline ? "textarea" : "text")}
      label={label}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      rows={multiline ? rows : null}
      themeColor={themeColor}
      touched={touched}
      valid={valid}
      value={value}
      errorIcon={errorIcon}
    />
  );
}

export default TextInput;

TextInput.propTypes = {
  /** The text above the text field that identifies the text field */
  label: PropTypes.string,
  /** helper text */
  helperText: PropTypes.string,
  /** placeholder text */
  placeholder: PropTypes.string,
  /** The name for the text field */
  name: PropTypes.string,
  /** Hex value for color theme */
  themeColor: PropTypes.string,
  /** The value for the text field */
  value: PropTypes.string,
  /** If the field is validated */
  validated: PropTypes.bool,
  /** whether it is a text field or a text area */
  multiline: PropTypes.bool,
  /** the error message that gets shown */
  error: PropTypes.node,
  /** whether or not the field is disabled */
  disabled: PropTypes.bool,
  /* HTML: Determines whether or not the input is required */
  required: PropTypes.bool,
  /** Number of rows to display in textarea */
  rows: PropTypes.number
}
