import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { createUniversalPortal } from './helpers/react';
import { connectLocation } from './location/LocationConnectors';
import Theme from '../themes';
import withDefaultPrevented from '../withDefaultPrevented';

const NoScrollStyle = css`
  body {
    overflow: hidden;
  }
`;
// disable scrolling the body while modal is open. Modal may have it's own scrolling.
const NoScroll = () => <Global styles={NoScrollStyle} />;

const ThemedClose = styled.a`
  &.close {
    &:hover, &:focus {
      color: ${Theme.colors.brand};
    }
  }
`;

// _Modal_: React Component for creating Modal.
//        It is injected only if it's open.
//
// Example:
// ```
// <Modal open={openVariable} onClose={toggleOpenVariable}>Content</Modal>
// ```
class Modal extends Component {
  constructor(props) {
    super(props);

    this.onCloseClick = withDefaultPrevented(() => {
      if(this.focusedElementBeforeOpen)
        this.focusedElementBeforeOpen.focus();

      this.props.onClose();
    });

    this.keyUp = this.keyUp.bind(this);

    this.elementFunc = props.portal || (() => this.el || (this.el = document.createElement('div')));
  }

  focusOnOpen() {
    if (this.props.open) {
      this.focusedElementBeforeOpen = document.activeElement;
      this.focusableElements = Array.prototype.slice.call(this.modal.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'));
      this.firstFocusableElement = this.focusableElements[0];
      this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];
      this.modalTabBoundaryStart = this.modalBoundaryStart;
      this.modalTabBoundaryEnd = this.modalBoundaryEnd;
      this.firstFocusableElement.focus();
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.keyUp);
    if(!this.props.portal)
      document.body.appendChild(this.elementFunc());

    this.focusOnOpen();
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.keyUp);
    if(!this.props.portal)
      document.body.removeChild(this.elementFunc());
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open) {
      this.focusOnOpen();
    }
  }

  keyUp(event) {
    const KEY_TAB = 9;
    const KEY_ESC = 27;

    if (!this.props.open) {
      return;
    }

    switch (event.keyCode) {
      case KEY_TAB:
        if (event.shiftKey) {
          this.handleBackwardTab();
        } else {
          this.handleForwardTab();
        }
        break;
      case KEY_ESC:
        if (this.isDismissable()) this.onCloseClick(event);
        break;
      default:
        break;
    }
  }

  isDismissable() {
    const { dismissible } = this.props;
    return dismissible !== false;
  }

  handleBackwardTab() {
    if (document.activeElement == this.modalTabBoundaryStart) {
      this.lastFocusableElement.focus();
    }
  }

  handleForwardTab() {
    if (document.activeElement == this.modalTabBoundaryEnd) {
      this.firstFocusableElement.focus();
    }
  }

  render() {
    const { name, children, open, className = '', t, size } = this.props;
    if (!open) {
      return null;
    }

    return createUniversalPortal((
      <div className={`oc-modal ${className} ${open ? 'no-target' : ''}`} role="dialog" aria-labelledby="modal-title">
        <NoScroll />
        {this.isDismissable() && <a
          href="#"
          className="global-close"
          data-track="close-modal"
          data-track-modal-name={name}
          onClick={this.onCloseClick}
          aria-hidden="true"
          aria-label={t('owenscorning.components.modal.close')}
          tabIndex="-1"
        />}

        <h3 id="modal-title" style={{ display: 'none' }}>{name}</h3>

        <a href="#" id="modal-tab-boundary-start" ref={(element) => { this.modalBoundaryStart = element }}></a>
        <div className={`modal-body ${size || '' }`} ref={(element) => { this.modal = element }} data-am-region={this.props['data-am-region']} >
        {this.isDismissable() && <ThemedClose
            href="#"
            className="close"
            data-track="close-modal"
            data-track-modal-name={name}
            onClick={this.onCloseClick}
            tabIndex="0"
            aria-label={t('owenscorning.components.modal.close')}
          > 
            <span aria-hidden="true">&times;</span>
          </ThemedClose>}

          <div className="modal-content">
            {children}
          </div>
        </div>
        <a href="#" id="modal-tab-boundary-end" ref={(element) => { this.modalBoundaryEnd = element }}></a>
      </div>
    ), this.elementFunc)
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.oneOf([true, false, null, undefined]).isRequired,
  portal: PropTypes.oneOfType([PropTypes.func, null]).isRequired,
  size: PropTypes.string,
  dismissible: PropTypes.bool,
}

Modal.defaultProps = {
  className: '',
  size: '',
  name: 'unnamed-modal',
  onClose: () => {},
  dismissible: true,
}

export default connectLocation(Modal);
