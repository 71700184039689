class Cookies {
  static getAll = () => {
    const decoded = decodeURIComponent(document.cookie);
    const cookieArr = decoded.split(';');
    const hash = {};
    cookieArr.forEach(c => {
      const [cKey, cValue] = c.trim().split('=');
      hash[cKey] = cValue;
    });

    return hash;
  }

  static get = (name) => {
    return this.getAll()[name];
  }

  static getFeatureFlags = () => {
    const json = this.get('feature_flags_v2');
    if (json) return JSON.parse(json);

    return {enabled: []};
  }

  static isReferred = () => {
    return this.getFeatureFlags().enabled.includes('contractor_referral');
  }
}

export const USER_ZIP_COOKIE = 'UserInfoZip2';
export const USER_LOCALE_COOKIE = 'oc_locale2';

function sameSite() {
  if (typeof window === 'undefined' || typeof COOKIE_DOMAIN === 'undefined') {
    return '';
  }
  if (window.location?.protocol === 'https:') {
    return `;SameSite=None;Secure`;
  }
  return '';
}

// 1 year in seconds
const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

export function setLocaleCookie(locale) {
  if (typeof window === 'undefined') return;
  if (typeof locale !== 'string' || locale.length < 1) return;

  document.cookie = `${USER_LOCALE_COOKIE}=${locale};max-age=${COOKIE_MAX_AGE};path=/${sameSite()}`;
}

export function setZipCookie(zip, locale) {
  const country = locale?.includes('-')
    ? locale.split('-')[1]?.toUpperCase()
    : null;
  document.cookie = `${USER_ZIP_COOKIE}=ZipCode=${
    zip || ''
  }&Country=${country};max-age=${COOKIE_MAX_AGE};path=/${sameSite()}`;
}

export function setFeatureFlagsCookie(flags) {
  const features = window.escape(`{"enabled":[${flags.map(x => `"${x}"`)}]}`);
  document.cookie = `feature_flags_v2=${features};max-age=${COOKIE_MAX_AGE};path=/`;
}

export default Cookies;
