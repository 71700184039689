import { isLocaleMatch, localeSort } from './locales';

/*
 * returns a sort function for locales, given a user's preferred locale
 * if the user's language is available for the country, it should be first
 * otherwise use our predefined locale ordering
 */
function userLocaleSort(userLocale) {
  if (!userLocale?.code?.length) {
    return localeSort;
  }
  const genericForLanguage = { code: userLocale.code.split('-')[0] };
  return (a, b) => {
    const aMatch = isLocaleMatch(genericForLanguage, a);
    const bMatch = isLocaleMatch(genericForLanguage, b);
    if (aMatch && !bMatch) {
      return -1;
    }
    if (!aMatch && bMatch) {
      return 1;
    }
    return localeSort(a, b);
  };
}

export default userLocaleSort;
