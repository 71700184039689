/* eslint-disable no-param-reassign */
import _ from 'lodash';

import Cookies, {
  setZipCookie,
  setFeatureFlagsCookie,
} from './PageBuilder/helpers/cookies';
import { PrefixWithLocale } from '../helpers/url_helpers';

// returns true if the object at the given path has changed
function isObjectChangedAtPath(previous, current, path) {
  return !_.isEqual(_.get(previous, path), _.get(current, path));
}

const FIXED_LOCATION_REDIRECTS = [
  pathname => {
    const matcher = /^(\/[a-z]{2}-[a-z]{2}\/roofing\/contractors)\/locations\/[a-z]{2}\/[a-z]+/i;
    const match = pathname.match(matcher);
    if (match) {
      return match[1];
    }
    return null;
  },
];
// when location is fixed for a page, if a user changes their location
// we must change redirect to a page where the location is not fixed
// this happens on contractor city pages
function fixedLocationChangeEffects(
  previousState,
  currentState,
  nextUrl,
  isCookieReload
) {
  const zipChanged = isObjectChangedAtPath(
    previousState,
    currentState,
    'location.zip'
  );
  const localeChanged = isObjectChangedAtPath(
    previousState,
    currentState,
    'location.locale.code'
  );
  if (!(zipChanged || localeChanged)) {
    return [nextUrl, isCookieReload];
  }
  for (let i = 0; i < FIXED_LOCATION_REDIRECTS.length; i += 1) {
    const redirect = FIXED_LOCATION_REDIRECTS[i];
    const pathname = redirect(nextUrl.pathname);
    if (pathname) {
      nextUrl.pathname = pathname;
      return [nextUrl, true];
    }
  }
  return [nextUrl, isCookieReload];
}

function locationChangeEffects(
  previousState,
  currentState,
  nextUrl,
  isCookieReload
) {
  const zipChanged = isObjectChangedAtPath(
    previousState,
    currentState,
    'location.zip'
  );
  const localeChanged = isObjectChangedAtPath(
    previousState,
    currentState,
    'location.locale.code'
  );
  const zip = _.get(currentState, 'location.zip');
  const locale = _.get(currentState, 'location.locale.code');

  if (zipChanged) {
    setZipCookie(zip, locale);
    if (zip?.length) {
      // reload if zip is set, not necessary if it's cleared
      isCookieReload = true;
    }
  }

  if (localeChanged) {
    const newPath = PrefixWithLocale(nextUrl.pathname, locale);
    nextUrl.pathname = newPath;
  }

  return [nextUrl, isCookieReload];
}

function featureFlagChangeEffects(
  previousState,
  currentState,
  nextUrl,
  isCookieReload
) {
  const featureFlagsChanged = isObjectChangedAtPath(
    previousState,
    currentState,
    'featureFlag.previewFeatureFlags'
  );

  if (featureFlagsChanged) {
    const currentFeatureFlags = _.get(
      currentState,
      'featureFlag.previewFeatureFlags'
    );
    const previousFeatureFlags = _.get(
      previousState,
      'featureFlag.previewFeatureFlags'
    );
    const nextCookiedFlags =
      Cookies.getFeatureFlags()?.enabled?.filter(
        x =>
          !previousFeatureFlags.includes(x) || currentFeatureFlags.includes(x)
      ) || [];
    setFeatureFlagsCookie(nextCookiedFlags);
    return [nextUrl, true];
  }

  return [nextUrl, isCookieReload];
}

export default store => {
  if (typeof window !== 'undefined') {
    let currentState = store.getState();
    let previousState = currentState;

    // ZIP or locale change
    return store.subscribe(() => {
      previousState = currentState;
      currentState = store.getState();
      const currentUrl = new URL(window.location);
      let nextUrl = new URL(window.location);
      let isCookieReload = false;

      // page reload/navigation logic boils down to basically
      // if locale is changed (url will change), we need to load the page with the new locale
      // if the zip cookie or feature flags cookie changes, we should reload to pick those changes up
      [nextUrl, isCookieReload] = locationChangeEffects(
        previousState,
        currentState,
        nextUrl,
        isCookieReload
      );
      [nextUrl, isCookieReload] = fixedLocationChangeEffects(
        previousState,
        currentState,
        nextUrl,
        isCookieReload
      );
      [nextUrl, isCookieReload] = featureFlagChangeEffects(
        previousState,
        currentState,
        nextUrl,
        isCookieReload
      );

      const urlChanged = currentUrl.toString() !== nextUrl.toString();
      if (isCookieReload || urlChanged) {
        // `replace` with the same URL is equivalent to a reload
        window.location.replace(nextUrl.toString());
      }
    });
  }
  return () => {};
};
