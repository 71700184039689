
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import Link from './oc-link';
import _ from 'lodash';
import Form from '../OC/PageBuilder/Form';
import Theme from '../../themes/index';


const phoneNumberStyles = css`
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 29px;
  color: ${Theme.colors.brand};
  text-decoration: none;
  margin-bottom: 10px;
`;

const PinkLinkStyles = css`
  display: inline-flex;
  align-items: center;
  color: ${Theme.colors.brand};
  text-transform: uppercase;
  font-family: Oswald;
  font-weight: 500;
  text-decoration: none;
  margin-right: 45px;
  cursor: pointer;

  svg {
    margin-right: 15px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
`;

const plainPhone = css`
  font-size: 18px;
  color: #333;
  font-weight: normal;
  display: block;

  @media (min-width: 768px) {
    display: inline;
  }
`;


const IconPhone = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.871857 1.15361L5.74676 0.0286313C6.27643 -0.0932411 6.82017 0.183316 7.03579 0.68018L9.28575 5.93007C9.48262 6.38943 9.35137 6.92849 8.96232 7.24254L6.12175 9.56749C7.80922 13.1627 10.7576 16.1533 14.4278 17.8736L16.7528 15.033C17.0715 14.6439 17.6059 14.5127 18.0653 14.7096L23.3152 16.9595C23.8167 17.1798 24.0933 17.7236 23.9714 18.2532L22.8464 23.1281C22.7292 23.6344 22.2792 24 21.7496 24C9.74511 24 0 14.2736 0 2.25046C0 1.72547 0.360929 1.27079 0.871857 1.15361Z" fill={Theme.colors.brand}/>
  </svg>
);

const IconEmail = () => (
  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9738 10.6138C14.1308 11.2653 12.9012 12.2155 12 12.1999C11.0988 12.2155 9.86919 11.2653 9.02622 10.6138C8.85198 10.4792 8.69426 10.3573 8.55937 10.2593C2.79418 6.07554 1.96567 5.42716 0.733286 4.46272C0.636 4.38659 0.536197 4.30848 0.43125 4.22651C0.159375 4.01089 0 3.68745 0 3.34058V2.44995C0 1.20776 1.00781 0.199951 2.25 0.199951H21.75C22.9922 0.199951 24 1.20776 24 2.44995V3.34058C24 3.68745 23.8406 4.01558 23.5688 4.22651C23.4883 4.2896 23.4109 4.35038 23.3353 4.40972C22.0632 5.40863 21.3121 5.99839 15.4406 10.2593C15.3057 10.3573 15.148 10.4792 14.9738 10.6138ZM24 6.36402C24 6.13433 23.7281 5.99839 23.5453 6.1437C22.4953 6.95933 21.1078 7.99995 16.3266 11.4734C16.1815 11.5791 16.019 11.7048 15.8419 11.8418C14.8569 12.6038 13.4184 13.7166 12 13.7046C10.5882 13.7126 9.17305 12.6206 8.17244 11.8485C7.99364 11.7106 7.82807 11.5828 7.67812 11.4734C2.89688 7.99995 1.50469 6.96402 0.454688 6.14839C0.267188 6.00308 0 6.13433 0 6.3687V15.95C0 17.1921 1.00781 18.2 2.25 18.2H21.75C22.9922 18.2 24 17.1921 24 15.95V6.36402Z" fill={Theme.colors.brand}/>
  </svg>
);

const FormIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path fill="currentColor" d="M21 12H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1ZM8 10h12V7.94H8Z" class="clr-i-outline clr-i-outline-path-1" /><path fill="currentColor" d="M21 14.08H7a1 1 0 0 0-1 1V19a1 1 0 0 0 1 1h11.36L22 16.3v-1.22a1 1 0 0 0-1-1ZM20 18H8v-2h12Z" class="clr-i-outline clr-i-outline-path-2" />
    <path fill="currentColor" d="M11.06 31.51v-.06l.32-1.39H4V4h20v10.25l2-1.89V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h8a3.44 3.44 0 0 1 .06-.49Z" class="clr-i-outline clr-i-outline-path-3" /><path fill="currentColor" d="m22 19.17l-.78.79a1 1 0 0 0 .78-.79Z" class="clr-i-outline clr-i-outline-path-4" />
    <path fill="currentColor" d="M6 26.94a1 1 0 0 0 1 1h4.84l.3-1.3l.13-.55v-.05H8V24h6.34l2-2H7a1 1 0 0 0-1 1Z" class="clr-i-outline clr-i-outline-path-5" /><path fill="currentColor" d="m33.49 16.67l-3.37-3.37a1.61 1.61 0 0 0-2.28 0L14.13 27.09L13 31.9a1.61 1.61 0 0 0 1.26 1.9a1.55 1.55 0 0 0 .31 0a1.15 1.15 0 0 0 .37 0l4.85-1.07L33.49 19a1.6 1.6 0 0 0 0-2.27ZM18.77 30.91l-3.66.81l.89-3.63L26.28 17.7l2.82 2.82Zm11.46-11.52l-2.82-2.82L29 15l2.84 2.84Z" class="clr-i-outline clr-i-outline-path-6" />
    <path fill="none" d="M0 0h36v36H0z" />
  </svg>
)

function extractFormUid(input) {
  // Remove query params if any
  const clean = input.split('?')[0];

  // If no slashes at all, just return as-is
  if (!clean.includes('/')) {
    return clean;
  }

  // Else, split by `/` and return last part
  return clean.split('/').filter(Boolean).pop();
}

const ContactUs = ({ phoneLabel, phoneReal, email, emailUs, callUs, formLabel, form }) => {
  const phone = phoneReal || phoneLabel;
  emailUs = _.isEmpty(emailUs) ? 'Email Us' : emailUs;
  callUs = _.isEmpty(callUs) ? 'Call Us' : callUs;

  const handleClick = (form) => (
    Board.modal.open({
      body: <Form { ...form } />
    })
  )

  return (
    <div>
      { phone && <Link href={`tel:${ phone }`} css={ phoneNumberStyles } analytics={{ track: "phone" }} >{ phoneLabel }</Link> }
      <LinkWrapper>
        { email && <Link href={`mailto:${ email }`} css={ PinkLinkStyles } analytics={{ track: "email" }} ><IconEmail/> { emailUs }</Link>}
        { phone && <Link href={`tel:${ phone }`} css={ PinkLinkStyles } analytics={{ track: "phone" }} ><IconPhone/> { callUs }</Link> }
        { formLabel && (form?.form_uid || form?.form_url) &&
        <Link
          href="#"
          css={ PinkLinkStyles }
          analytics={{ track: "form-start", "form-name":  extractFormUid(form.form_uid || form.form_url) }}
          onClick={() => handleClick(form)}
          >
          <FormIcon/> { formLabel }
        </Link>}
      </LinkWrapper>
    </div>
  );
};

export default ContactUs;
